import ClipboardJS from 'clipboard';
import { Tooltip } from 'bootstrap';

( function() {
  // Insert copy to clipboard button before .highlight
  var btnHtml = '<div class="docs-clipboard"><button type="button" class="btn-clipboard" title="Copy to clipboard">Copy</button></div>'
  document.querySelectorAll( 'div.highlight' )
    .forEach( function ( element ) {
      element.insertAdjacentHTML( 'beforebegin', btnHtml );
    } )

  document.querySelectorAll( '.btn-clipboard' )
    .forEach( function ( btn ) {
      var tooltipBtn = new Tooltip( btn );

      btn.addEventListener( 'mouseleave', function () {
        // Explicitly hide tooltip, since after clicking it remains
        // focused (as it's a button), so tooltip would otherwise
        // remain visible until focus is moved away
        tooltipBtn.hide();
      } )
    } )

  var clipboard = new ClipboardJS( '.btn-clipboard', {
    target: function ( trigger ) {
      return trigger.parentNode.nextElementSibling;
    }
  } )

  clipboard.on( 'success', function ( event ) {
    var tooltipBtn = Tooltip.getInstance( event.trigger );

    event.trigger.setAttribute( 'data-bs-original-title', 'Copied!' );
    tooltipBtn.show();

    event.trigger.setAttribute( 'data-bs-original-title', 'Copy to clipboard' );
    event.clearSelection();
  } )

  clipboard.on( 'error', function ( event ) {
    var modifierKey = /mac/i.test( navigator.userAgent ) ? '\u2318' : 'Ctrl-';
    var fallbackMsg = 'Press ' + modifierKey + 'C to copy';
    var tooltipBtn = Tooltip.getInstance( event.trigger );

    event.trigger.setAttribute( 'data-bs-original-title', fallbackMsg );
    tooltipBtn.show();

    event.trigger.setAttribute( 'data-bs-original-title', 'Copy to clipboard' );
  } )
} )()
