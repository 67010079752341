import Swiper from 'swiper/bundle';

/**
 * Swiper init for all example pages
 * @return {[type]} [description]
 */
( function() {
  let activeIndex = 0
  var sliderThumbs = new Swiper( '.js-swiper-corporate-thumbs', {
    slidesPerView: 1,
    autoplay: false,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    followFinger: false,
    loop: true,
    on: {
      'slideChangeTransitionEnd': function ( event ) {
        if ( sliderMain === undefined ) {
          return
        }
        sliderMain.slideTo( event.activeIndex )
      }
    }
  } );

  var sliderMain = new Swiper( '.js-swiper-corporate-main', {
    effect: 'fade',
    autoplay: false,
    disableOnInteraction: true,
    loop: true,
    navigation: {
      nextEl: '.js-swiper-corporate-main-button-next',
      prevEl: '.js-swiper-corporate-main-button-prev',
    },
    thumbs: {
      swiper: sliderThumbs
    },
    on: {
      'slideChangeTransitionEnd': function ( event ) {
        if ( sliderThumbs === undefined ) {
          return
        }
        sliderThumbs.slideTo( event.activeIndex )
      }
    }
  } )

  // Clients
  var swiper = new Swiper( '.js-swiper-corporate-clients',{
    slidesPerView: 2,
    breakpoints: {
      380: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 15,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 15,
      },
    },
  } );

  // Card Grid
  var swiper = new Swiper( '.js-swiper-corporate-card-blocks',{
    slidesPerView: 1,
    pagination: {
      el: '.js-swiper-corporate-card-blocks-pagination',
      dynamicBullets: true,
      clickable: true,
    },
    breakpoints: {
      620: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
    },
  } );
} )()
