import Chart from 'chart.js/auto';

( function() {
  const charts = document.querySelectorAll( '.js-chart' );
  Array.from( charts ).forEach( chart => {
    const el = typeof chart === 'string' ? document.querySelector( chart ) : chart;

    if ( !el ) {
      return;
    }

    const settings = el.hasAttribute( 'data-caps-chartjs-options' ) ? JSON.parse( el.getAttribute( 'data-caps-chartjs-options' ) ) : {};

    new Chart( el, settings );
  } );
} )()
