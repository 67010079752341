import * as bootstrap from 'bootstrap';

const CAPSCore = {
  init: () => {
    // Botostrap Tootltips
    var tooltipTriggerList = [].slice.call( document.querySelectorAll( '[data-bs-toggle="tooltip"]' ) );
    tooltipTriggerList.map( function ( tooltipTriggerEl ) {
      return new bootstrap.Tooltip( tooltipTriggerEl );
    } );

    // Bootstrap Popovers
    var popoverTriggerList = [].slice.call( document.querySelectorAll( '[data-bs-toggle="popover"]' ) );
    popoverTriggerList.map( function ( popoverTriggerEl ) {
      return new bootstrap.Popover( popoverTriggerEl );
    } );

    // Bootstrap Toasts
    var toastBtns = document.querySelectorAll( '.js-toast-btn' );
    Array.from( toastBtns ).forEach( toastBtn => {
      var toastEl = toastBtn.getAttribute( 'aria-controls' );
      toastBtn.addEventListener( 'click', function() {
        var toast = new bootstrap.Toast( `#${toastEl}` )

        toast.show();
      } );
    } );
  },
  components: {},
};

CAPSCore.init();
