import AOS from 'aos';

( function() {
  window.AOS = AOS;

  AOS.init( {
    duration: 650,
    once: true
  } );
} )()
