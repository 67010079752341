import { ScrollSpy } from 'bootstrap';
import HSScrollspy from '../plugins/scrollspy';

( function() {
  if ( document.querySelector( '#navbarSettingsEg2' ) === null ) {
    return;
  }

  // Documentation implementation
  new ScrollSpy( document.body, {
    target: '#navbarSettingsEg2',
    offset: 90
  } );

  new HSScrollspy( '#navbarVerticalNavMenuEg2', {
    breakpoint: 'lg',
    resetOffset: 'sm'
  } );
} )()
