import Swiper from 'swiper/bundle';

/**
 * Swiper init for all example pages
 * @return {[type]} [description]
 */
( function() {
  var swiper = new Swiper( '.js-swiper-single-testimonials', {
    pagination: {
      el: '.js-swiper-single-testimonials-pagination',
      clickable: true,
    },
  } );
} )()
