import Swiper from 'swiper/bundle';

( function() {
  // Default
  var swiper = new Swiper( '.js-swiper' );

  // Navigation
  var navigation = new Swiper( '.js-swiper-navigation', {
    navigation: {
      nextEl: '.js-swiper-navigation-button-next',
      prevEl: '.js-swiper-navigation-button-prev',
    },
  } );

  // Pagination
  var pagination = new Swiper( '.js-swiper-pagination', {
    pagination: {
      el: '.js-swiper-pagination-element',
      clickable: true,
    },
  } );

  // Pagination Dynamic
  var paginationDynamic = new Swiper( '.js-swiper-pagination-dynamic', {
    pagination: {
      el: '.js-swiper-pagination-dynamic-element',
      dynamicBullets: true,
    },
  } );

  // Pagination Progress
  var paginationProgress = new Swiper( '.js-swiper-pagination-progress', {
    pagination: {
      el: '.js-swiper-pagination-progress-element',
      type: 'progressbar',
    },
    navigation: {
      nextEl: '.js-swiper-pagination-progress-button-next',
      prevEl: '.js-swiper-pagination-progress-button-prev',
    },
  } );

  // Pagination Thumbs
  var paginationProgressWithThumbsThumbs = new Swiper( '.js-swiper-pagination-progress-with-thumbs', {
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    history: false,
    breakpoints: {
      480: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
    },
    on: {
      'afterInit': function ( swiper ) {
        swiper.el.querySelectorAll( '.js-swiper-pagination-progress-body-helper' )
          .forEach( $progress => $progress.style.transitionDuration = `${swiper.params.autoplay.delay}ms` )
      }
    }
  } );

  var paginationProgressWithThumbsMain = new Swiper( '.js-swiper-pagination-progress-with-thumbs-main', {
    effect: 'fade',
    autoplay: true,
    loop: true,
    thumbs: {
      swiper: paginationProgressWithThumbsThumbs
    }
  } )

  // Pagination Fraction
  var paginationFraction = new Swiper( '.js-swiper-pagination-fraction', {
    pagination: {
      el: '.js-swiper-pagination-fraction-element',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.js-swiper-pagination-fraction-button-next',
      prevEl: '.js-swiper-pagination-fraction-button-prev',
    },
  } );

  // Vertical
  var vertical = new Swiper( '.js-swiper-vertical', {
    direction: 'vertical',
    pagination: {
      el: '.js-swiper-vertical-pagination',
      clickable: true,
    },
  } );

  // Space Between
  var spaceBetween = new Swiper( '.js-swiper-space-between', {
    spaceBetween: 30,
    pagination: {
      el: '.js-swiper-space-between-pagination',
      clickable: true,
    },
  } );

  // Sliders Per View
  var slidesPerView = new Swiper( '.js-swiper-slides-per-view', {
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
      el: '.js-swiper-slides-per-view-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-slides-per-view-button-next',
      prevEl: '.js-swiper-slides-per-view-button-prev',
    },
  } );

  // Sliders Per View Auto
  var slidesPerView = new Swiper( '.js-swiper-slides-per-view-auto', {
    slidesPerView: 'auto',
    spaceBetween: 30,
    pagination: {
      el: '.js-swiper-slides-per-view-auto-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-slides-per-view-auto-button-next',
      prevEl: '.js-swiper-slides-per-view-auto-button-prev',
    },
  } );

  // Responsive Breakpoint
  var responsiveBreakpoint = new Swiper( '.js-swiper-responsive-breakpoint', {
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: {
      el: '.js-swiper-responsive-breakpoint-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-responsive-breakpoint-button-next',
      prevEl: '.js-swiper-responsive-breakpoint-button-prev',
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    }
  } );

  // Centered
  var centered = new Swiper( '.js-swiper-centered', {
    slidesPerView: 4,
    spaceBetween: 30,
    centeredSlides: true,
    pagination: {
      el: '.js-swiper-centered-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-centered-button-next',
      prevEl: '.js-swiper-centered-button-prev',
    },
  } );

  // Centered Auto
  var centeredAuto = new Swiper( '.js-swiper-centered-auto', {
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 30,
    pagination: {
      el: '.js-swiper-centered-auto-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-centered-auto-button-next',
      prevEl: '.js-swiper-centered-auto-button-prev',
    },
  } );

  // Freemode
  var freemode = new Swiper( '.js-swiper-freemode', {
    slidesPerView: 3,
    spaceBetween: 30,
    freeMode: true,
    pagination: {
      el: '.js-swiper-freemode-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-freemode-button-next',
      prevEl: '.js-swiper-freemode-button-prev',
    },
  } );

  // Infinite Loop
  var infiniteLoop = new Swiper( '.js-swiper-infinite-loop', {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    pagination: {
      el: '.js-swiper-infinite-loop-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-infinite-loop-button-next',
      prevEl: '.js-swiper-infinite-loop-button-prev',
    },
  } );

  // Effect Fade
  var effectFade = new Swiper( '.js-swiper-effect-fade', {
    spaceBetween: 30,
    effect: 'fade',
    pagination: {
      el: '.js-swiper-effect-fade-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-effect-fade-button-next',
      prevEl: '.js-swiper-effect-fade-button-prev',
    },
  } );

  // Mousewheel
  var mousewheel = new Swiper( '.js-swiper-mousewheel', {
    slidesPerView: 1,
    spaceBetween: 30,
    mousewheel: true,
    pagination: {
      el: '.js-swiper-mousewheel-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-mousewheel-button-next',
      prevEl: '.js-swiper-mousewheel-button-prev',
    },
  } );

  // Autoplay
  var autoplay = new Swiper( '.js-swiper-autoplay', {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.js-swiper-autoplay-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-autoplay-button-next',
      prevEl: '.js-swiper-autoplay-button-prev',
    },
  } );

  // Equal Height
  var equalHeight = new Swiper( '.js-swiper-equal-height', {
    pagination: {
      el: '.js-swiper-equal-height-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-equal-height-button-next',
      prevEl: '.js-swiper-equal-height-button-prev',
    },
    breakpoints: {
      580: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
    }
  } );

  // Auto Height
  var autoHeight = new Swiper( '.js-swiper-auto-height', {
    autoHeight: true, //enable auto height
    spaceBetween: 20,
    pagination: {
      el: '.js-swiper-auto-height-pagination',
      clickable: true,
    },
  } );

  // Thumbs Gallery
  var galleryThumbs = new Swiper( '.js-swiper-gallery-thumbs', {
    spaceBetween: 10,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  } );

  var galleryTop = new Swiper( '.js-swiper-gallery-main', {
    spaceBetween: 10,
    navigation: {
      nextEl: '.js-swiper-gallery-button-next',
      prevEl: '.js-swiper-gallery-button-prev',
    },
    thumbs: {
      swiper: galleryThumbs
    }
  } );


  // Preloader
  var preloader = new Swiper( '.js-swiper-preloader', {
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
      el: '.js-swiper-preloader-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-swiper-preloader-button-next',
      prevEl: '.js-swiper-preloader-button-prev',
    },
    on: {
      'imagesReady': function ( swiper ) {
        const preloader = swiper.el.querySelector( '.js-swiper-preloader' )
        preloader.parentNode.removeChild( preloader )
      }
    }
  } );

  // UI Kit card block
  var uiKitCardBlock = new Swiper( '.js-swiper-card-blocks',{
    slidesPerView: 1,
    pagination: {
      el: '.js-swiper-card-blocks-pagination',
      dynamicBullets: true,
      clickable: true,
    },
    breakpoints: {
      620: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
    },
  } );

  // UI Kit card block
  var uiKitCardHero = new Swiper( '.js-swiper-course-hero',{
    preloaderClass: 'custom-swiper-lazy-preloader',
    navigation: {
      nextEl: '.js-swiper-course-hero-button-next',
      prevEl: '.js-swiper-course-hero-button-prev',
    },
    slidesPerView: 1,
    loop: 1,
    breakpoints: {
      380: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      580: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 15,
      },
      1024: {
        slidesPerView: 6,
        spaceBetween: 15,
      },
    },
    on: {
      'imagesReady': function ( swiper ) {
        const preloader = swiper.el.querySelector( '.js-swiper-course-hero-preloader' )
        preloader.parentNode.removeChild( preloader )
      }
    }
  } );
} )()
