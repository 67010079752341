import { ScrollSpy } from 'bootstrap';
import HSScrollspy from '../plugins/scrollspy';

( function() {
  if ( document.querySelector( '#navbarNavOnepageNav' ) === null ) {
    return;
  }

  // One page nav
  new ScrollSpy( document.body, {
    target: '#navbarNavOnepageNav'
  } );

  new HSScrollspy( '#navbarNavOnepage', {
    breakpoint: 'lg',
    scrollOffset: -10
  } );
} )()
