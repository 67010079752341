import Swiper from 'swiper/bundle';

/**
 * Swiper init for all example pages
 * @return {[type]} [description]
 */
( function() {
  var sliderThumbs = new Swiper( '.js-swiper-blog-showcase-hero-thumbs', {
    direction: 'vertical',
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    resizeObserver: false,
    slidesPerView: 3,
    spaceBetween: 100,
    history: false,
    on: {
      'afterInit': function ( swiper ) {
        swiper.el.style.opacity = 1;
        swiper.el.querySelectorAll( '.js-swiper-pagination-progress-body-helper' )
          .forEach( $progress => $progress.style.transitionDuration = `${swiper.params.autoplay.delay}ms` );
      }
    }
  } );

  var swiper = new Swiper( '.js-swiper-blog-showcase-hero', {
    effect: 'fade',
    autoplay: true,
    loop: true,
    pagination: {
      el: '.js-swiper-blog-showcase-hero-pagination',
      clickable: true,
    },
    thumbs: {
      swiper: sliderThumbs
    }
  } );
} )()
